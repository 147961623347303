<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit }"
    >
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            :src="recordData.logo_url"
            :text="avatarText(recordData.name)"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
        </template>
        <!-- <h4 class="mb-1">
          {{ recordData.fullName }}
        </h4> -->
        <div class="d-flex flex-wrap">
          <b-button
            @click="$emit('toggle-logo-upload')"
            variant="primary"
          >{{$t('forms.update')}}</b-button>
          <!-- <b-button
            variant="outline-secondary"
            class="ml-1"
          >
            <span class="d-none d-sm-inline">Remove</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button> -->
        </div>
      </b-media>
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(submitChanges)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Name -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.name')"
              rules="required|max:255"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                :label="$t('fields.name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="recordData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <!-- Field: Address -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
            #default="validationContext"
            :name="$t('fields.address')"
            rules="max:255"
          >
            <b-form-group
              :label="$t('fields.address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="recordData.address"
                :state="getValidationState(validationContext)"
                
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </b-col>

          <!-- Field: Community -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.community')"
              rules="max:255"
            >
              <b-form-group
                :label="$t('fields.community')"
                label-for="community"
              >
                <b-form-input
                  id="community"
                  v-model="recordData.community"
                  :state="getValidationState(validationContext)"
                  
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: CP -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.zip_code')"
              rules="max:255"
            >
              <b-form-group
                :label="$t('fields.zip_code')"
                label-for="cp"
              >
                <b-form-input
                  id="cp"
                  v-model="recordData.cp"
                  :state="getValidationState(validationContext)"
                  
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: countries -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.country')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                :label="$t('fields.country')"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="recordData.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries_options"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="country"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.phone')"
              rules="integer"
            >
              <b-form-group
                :label="$t('fields.phone')"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  type="number"
                  min="0"
                  v-model="recordData.phone"
                  :state="getValidationState(validationContext)"
                  
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Type -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.type')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                :label="$t('fields.type')"
                label-for="type"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="recordData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typesOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        <!-- Bilingual -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
            >
              <b-form-checkbox
                v-model="recordData.bilingual"
                plain
              >
                {{ $t('fields.bilingual_school')}}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- Field: Special Needs -->
          <!-- <b-col
            cols="12"
            md="8"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.nee')"
              rules=""
            >
              <b-form-group
                :label="$t('fields.nee')"
                label-for="special-needs"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="recordData.special_needs_selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="special_needs_options"
                  :reduce="val => val.value"
                  :clearable="false"
                  multiple

                  input-id="special-needs"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> -->

          

          <!-- Field: educational_stages -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.educational_stages')"
              rules=""
            >
              <b-form-group
                :label="$t('fields.educational_stages')"
                label-for="educational_stages"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="recordData.educational_stages_selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="educational_stages_options"
                  :reduce="val => val.value"
                  :clearable="false"
                  multiple

                  input-id="educational_stages"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>            
        <b-button
          variant="outline-secondary"
          type="button"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ name: 'school-view', params: { id: recordData.id } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { editSchool } from '@/api/routes'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules';
import { required, alphaNum, password, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'


export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    recordData: {
      type: Object,
      required: true,
    },
    special_needs_options: {
      type: Array,
      required: true,
    },
    countries_options: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
    extend('integer', {
      ...integer,
      message: this.$t('forms.errors.integer'),
    });
  },
  data(){
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return {
      required,
      alphaNum,
      password,

      minDate: tomorrow,
      typesOptions:[
        { 'value': 1, 'label': 'Público' },
        { 'value': 2, 'label': 'Privado' },
        { 'value': 3, 'label': 'Concertado' }
      ],
      educational_stages_options: [
        { 'value': '1', 'label': 'Infantil' },
        { 'value': '2', 'label': 'Primaria' },
        { 'value': '3', 'label': 'Secundaria' },
        { 'value': '4', 'label': 'Otros centros' }
      ],
    }
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    onFileChange(e){
      // console.log(e, 'onFileChange')
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // console.log(file, "createImage")
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        // console.log("------>>", e.target.result)
        vm.recordData.logo_url = e.target.result;
      };
      //reader.readAsDataURL(file);
    },
    validate(){
      if(!this.recordData.name){
        this.makeToast('danger', this.$t('Error'), 'Name missing');
        return false
      }
      if(!this.recordData.country){
        this.makeToast('danger', this.$t('Error'), 'Country missing');
        return false
      }
      if(!this.recordData.type){
        this.makeToast('danger', this.$t('Error'), 'School Type missing');
        return false
      }
      return true
    },
    async submitChanges(){
      if(this.validate()){  
        //// console.log(this.recordData)
        let data = {
            'tab': 'account',
            'id': this.recordData.id,
            'name':this.recordData.name,
            'address':this.recordData.address,
            'cp':this.recordData.cp,
            'phone':this.recordData.phone,
            'country':this.recordData.country,
            'community':this.recordData.community,
            'special_needs':  this.recordData.special_needs_selected.length >0 ? 1 :0,
            'special_needs_type': this.recordData.special_needs_selected,
            
            'type':this.recordData.type,
            'bilingual':this.recordData.bilingual,
            
            'educational_stages':this.recordData.educational_stages_selected,
            
            'logo_url':this.recordData.logo_url,
          }
          // console.log('data edit school', data)
        await this.$http.post(editSchool, data).then( response => {
          // console.log(response)
          if(response.data.status == 200){
            this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      avatarText,

      // form validation
      refFormObserver,
      getValidationState,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
